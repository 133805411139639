window.addEventListener(
  "load",
  () => {
    const appRoot = document.createElement("div");
    appRoot.setAttribute("id", "baltoRoot");

    document.body.appendChild(appRoot);

    const appVendor = document.createElement("script");
    appVendor.setAttribute("src", `${BUNDLE_URL}/baltoVendor.bundle.js`);
    document.head.appendChild(appVendor);

    const app = document.createElement("script");
    app.setAttribute("src", `${BUNDLE_URL}/baltoMain.bundle.js`);
    document.head.appendChild(app);
  },
  false
);
